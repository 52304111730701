
import React from 'react'
import Layout from '../components/layout'
import ScriptTag from 'react-script-tag'
import styled from '@emotion/styled'
import { textColor, plantinBoldItalic } from '../styles'

const PrivateEventsWrapper = styled.div`
  margin: 2rem;
  color: ${textColor};
  h2 {
    text-align: center;
    font-size: 3.8rem;
    margin: 0;
    padding: 2rem 1rem;
    position: relative;
    z-index: 1;
    font-family: ${plantinBoldItalic};
    letter-spacing: -0.04em;
    color: ${textColor};
  }
  #tripleseat_embed_form #lead_phone_number {
    width: 100%;
  }
`
const PrivateEventsPage = () => (
  <Layout>
    <PrivateEventsWrapper>
      <ScriptTag
        isHydrating={false}
        type="text/javascript"
        src="//api.tripleseat.com/v1/leads/ts_script.js?lead_form_id=4694&public_key=97c331594a3efae6bd3fa6bb735929e1be679b33"
      ></ScriptTag>
    </PrivateEventsWrapper>
  </Layout>
)
export default PrivateEventsPage
